import { Button, Paper, Stack, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";
import { Ingredient } from "../../model/Ingredient";
import { ApiService } from "../../services/ApiService";
import IngredientLine from "../meal_planner/IngredientLine";
import DeleteIcon from '@mui/icons-material/Delete';
import { Recipe } from "../../model/Recipe";
import { RecipeIngredient } from "../../model/RecipeIngredient";
import { useLocation, useParams } from "react-router-dom";

export default function EditRecipe() {

    const auth = useAuth();

    const params = useParams()
    const recipeId = params.id

    const { state } = useLocation();
    const recipe = state as Recipe;

    const [name, setName] = useState<string>(recipe.name);
    const [description, setDescription] = useState<string>(recipe.description);
    const [steps, setSteps] = useState<string[]>(recipe.steps);
    const [availableIngredients, setAvailableIngredients] = useState<Ingredient[]>([{} as Ingredient]);
    const [availableIngredientsKeys, setAvailableIngredientsKeys] = useState<string[]>([]);
    const [ingredients, setIngredients] = useState<Ingredient[]>([]);

    useEffect(() => {
        ApiService.getIngredients().then((response) => {
            let newIngredientKeys = [""];

            response.data.forEach(rspData => {
                newIngredientKeys.push(rspData.name)
            })

            setAvailableIngredientsKeys(newIngredientKeys)
            setAvailableIngredients(response.data)
        })
    }, []);

    useEffect(() => {
        setRecipeIngredients()
    }, [availableIngredients]);

    function setRecipeIngredients() {
        if (recipe.ingredients && availableIngredients.length > 1) {
            const selectedIngredients = recipe.ingredients.map(ing => {
                return availableIngredients
                    .filter(ingredient => {
                        return ingredient.id == ing.ingredientId
                    })[0]
            })
            setIngredients(selectedIngredients);
        }
    }

    function save() {
        const recipeIngredients = ingredients.map(i => {
            return {
                ingredientId: i.id,
                weight: i.weight
            } as RecipeIngredient;
        });

        ApiService.updateRecipe({
            id: recipeId,
            name: name,
            author: "author",
            description: description,
            steps: steps,
            meals: 1,
            ingredients: recipeIngredients

        } as Recipe, auth);
    }

    function addStep() {
        const currentSteps = steps;
        setSteps([...currentSteps, ""]);
    }

    function addIngredient() {
        const currentIngredients = ingredients;
        setIngredients([...currentIngredients, {} as Ingredient]);
    }

    function changeSelectedIngredient(index: number, newValue: string) {
        const currentIngredients = ingredients;
        currentIngredients[index] = availableIngredients
            .filter(ingredient => {
                return ingredient.name == newValue
            })[0];

        setIngredients([...currentIngredients])
    }

    function removeIngredient(index: number) {
        const currentIngredients = ingredients;

        setIngredients([...currentIngredients]);
    }

    function removeStep(index: number) {
        let currentSteps = steps;
        currentSteps.splice(index, 1);
        setSteps([...currentSteps]);
    }

    function changeSelectedIngredientWeight(index: number, newValue: number) {
        const currentIngredients = ingredients;

        currentIngredients[index].weight = newValue;

        setIngredients([...currentIngredients]);
    }

    return (
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <Stack spacing={2}>
                Edit recipe
                <TextField
                    required
                    id="name"
                    name="name"
                    label="Name"
                    fullWidth
                    variant="standard"
                    onChange={(event: any) => {
                        setName(event.target.value)
                    }}
                    value={name}
                />
                <TextField
                    required
                    id="description"
                    name="description"
                    label="description"
                    fullWidth
                    variant="standard"
                    onChange={(event: any) => {
                        setDescription(event.target.value)
                    }}
                    value={description}
                />
                {steps.map((step, index) => {
                    return <>
                        <TextField
                            required
                            id={"recipe-step-" + index}
                            name="step"
                            label={"step " + (index + 1)}
                            fullWidth
                            variant="standard"
                            onChange={(event: any) => {
                                let currentSteps = steps;
                                currentSteps[index] = event.target.value;
                                setSteps([...currentSteps]);
                                // steps[index] = event.target.value;
                            }}
                            value={steps[index]}
                        />
                        <DeleteIcon onClick={() => { removeStep(index) }} sx={{ mt: 1.5 }} />
                    </>
                })}

                <Button variant="contained" sx={{ mt: 3, ml: 1 }} onClick={addStep}>Add step</Button>

                {ingredients.map((ingrInput, index) => {
                    return <IngredientLine
                        key={ingrInput.id}
                        availableIngredientsKeys={availableIngredientsKeys}
                        index={index}
                        ingredient={ingrInput}
                        onChangeSelectedIngredients={changeSelectedIngredient}
                        onChangeSelectedIngredientsWeight={changeSelectedIngredientWeight}
                        onRemoveIngredient={removeIngredient}
                    />
                })}
                <Button variant="contained" sx={{ mt: 3, ml: 1 }} onClick={addIngredient}>Add ingredient</Button>

                <Button variant="contained" sx={{ mt: 3, ml: 1 }} onClick={save}>Save recipe</Button>
            </Stack>
        </Paper>
    )
}