import * as React from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Ingredient } from '../../model/Ingredient';
import { useEffect, useState } from 'react';
import MealSummary from './MealSummary';
import axios from 'axios';
import IngredientLine from './IngredientLine';
import { Meal } from '../../model/Meal';
import { mapFromIngredient, MealIngredient } from "../../model/MealIngredient";
import PlusIcon from '@mui/icons-material/Add';
import Tooltip from '@mui/material/Tooltip';
import { ApiService } from '../../services/ApiService';

export default function MealDetails(props: { meal: Meal, date: Date, onMealChange: (meal: Meal) => void }) {

  const [availableIngredients, setAvailableIngredients] = useState(new Map([]) as Map<string, Ingredient>);
  const [availableIngredientsById, setAvailableIngredientsById] = useState(new Map([]) as Map<number, Ingredient>);
  const [selectedIngredients, setSelectedIngredients] = useState<Ingredient[]>([]);
  // const [selectedIngredients, setSelectedIngredients] = useState([availableIngredients.get('scrambled egg') as Ingredient]);
  const [availableIngredientsKeys, setAvailableIngredientsKeys] = useState(Array.from(availableIngredients.keys()))
  const [mealName, setMealName] = useState(props.meal?.name);
  const tmpId = props.meal?.tmpId;

  const setAndNotifySelectedIngredients = (selectedIngredients: Ingredient[]) => {
    setSelectedIngredients(selectedIngredients);
    props.onMealChange({
      tmpId: tmpId,
      order: tmpId,
      name: mealName,
      ingredients: selectedIngredients.map(selected => mapFromIngredient(selected))
    });
  }

  useEffect(() => {
    ApiService.getIngredients().then((response) => {
      let newAvailableIngredients = availableIngredients;
      let newAvailableIngredientsById = availableIngredientsById;

      response.data.forEach(i => {
        i.weight = 100;
        newAvailableIngredients.set(i.name, i);
        newAvailableIngredientsById.set(i.id, i);
      });

      setAvailableIngredients(newAvailableIngredients)
      setAvailableIngredientsById(newAvailableIngredientsById)
      setAvailableIngredientsKeys(Array.from(availableIngredients.keys()))
    });
  }, []);


  useEffect(() => {
    if (availableIngredientsById.size > 0) {
      if (props.meal.basedOnRecipe) {
        setAndNotifySelectedIngredients(mapToIngredients(props.meal.basedOnRecipe.ingredients, availableIngredientsById))
      }
      else if (props.meal.ingredients) {
        setAndNotifySelectedIngredients(mapToIngredients(props.meal.ingredients, availableIngredientsById));
      }
      else {
        setAndNotifySelectedIngredients([availableIngredients.get('couscous') as Ingredient]);
      }
    }
  }, [availableIngredientsById, props.meal.tmpId, props.meal.basedOnRecipe, props.date]);

  function mapToIngredients(mealIngredients: MealIngredient[], availableIngredientsById: Map<number, Ingredient>): Ingredient[] {
    return mealIngredients.map(i =>
      availableIngredientsById.get(i.ingredientId)
    ).filter(i =>
      i !== null && i !== undefined
    ) as Ingredient[];
  }

  function addProduct() {
    let newIngredients = [...selectedIngredients];
    // newIngredients.push({ name: "boiled potato", carbohydratesIn100g: 20, glycemicIndex: 78, weight: 200 });
    newIngredients.push(availableIngredients.get('scrambled egg') as Ingredient);
    setAndNotifySelectedIngredients(newIngredients);
  }

  function changeSelectedIngredientType(index: number, newValue: string) {
    let newIngr = availableIngredients.get(newValue);
    if (newIngr) {
      let newIngredients = selectedIngredients;
      newIngredients[index] = newIngr;
      setAndNotifySelectedIngredients([...newIngredients]);
    }
  }

  function changeSelectedIngredientWeight(index: number, newValue: number) {
    let newIngredients = selectedIngredients;
    newIngredients[index].weight = newValue;
    setAndNotifySelectedIngredients([...newIngredients]);
  }

  function removeInredient(index: number) {
    let newIngredients = selectedIngredients;
    newIngredients.splice(index, 1);
    setAndNotifySelectedIngredients([...newIngredients]);
  }

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        {props.meal.basedOnRecipe ? props.meal.basedOnRecipe.name : ''} Ingredients
      </Typography>
      <br />
      <Grid container spacing={3}>

        {selectedIngredients.map((i, index) => {
          return <IngredientLine
            key={i.id}
            availableIngredientsKeys={availableIngredientsKeys}
            index={index}
            ingredient={i}
            onChangeSelectedIngredients={changeSelectedIngredientType}
            onChangeSelectedIngredientsWeight={changeSelectedIngredientWeight}
            onRemoveIngredient={removeInredient}
          />
        })}
        <Grid item xs={12}>
          {/*           <Tooltip title={'Add new ingredient to your meal'}> */}
          <PlusIcon onClick={() => addProduct()}
            sx={{ mt: 0 }} />
          {/*           </Tooltip> */}
          <br />
          <br />
        </Grid>
        <Grid item xs={12}>
          <MealSummary selectedIngredients={selectedIngredients} />
        </Grid>
      </Grid>

    </React.Fragment>
  );
}
