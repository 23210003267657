import { AppBar, MenuItem, Toolbar, Typography } from "@mui/material"
import React from "react"
import { useAuth } from "react-oidc-context";
import { useNavigate } from "react-router-dom";

export function Navigation() {

  const auth = useAuth();
  const navigate = useNavigate();

  function handleRedirectMainPage(event: any): void {
    navigate('/');
  }

  function handleRedirectIngredients(event: any): void {
    navigate('/ingredients');
  }
  function handleRedirectAddIngredient(event: any): void {
    navigate('/add_ingredient');
  }

  function handleRedirect(event: any, destination: string): void {
    navigate(destination);
  }

  return (
    <AppBar
      position="absolute"
      color="primary"
      elevation={0}
      sx={{
        position: 'relative',
        borderBottom: (t) => `1px solid ${t.palette.divider}`,
      }}
    >
      <Toolbar>
        <Typography variant="h6" color="inherit" noWrap>
        </Typography>
        Plan your meals {auth.user?.profile.preferred_username}

        <MenuItem
          // key={page} 
          onClick={handleRedirectMainPage}
        >
          <Typography sx={{ textAlign: 'center' }}>Main Page</Typography>
        </MenuItem>

        <MenuItem
          // key={page} 
          onClick={handleRedirectAddIngredient}
        >
          <Typography sx={{ textAlign: 'center' }}>Add Ingredient</Typography>
        </MenuItem>

        <MenuItem
          // key={page} 
          onClick={handleRedirectIngredients}
        >
          <Typography sx={{ textAlign: 'center' }}>Ingredients</Typography>
        </MenuItem>

        <MenuItem
          onClick={(event) => handleRedirect(event, "/recipes")}
        >
          <Typography sx={{ textAlign: 'center' }}>Recipes</Typography>
        </MenuItem>
      </Toolbar>
    </AppBar>
  )
}
