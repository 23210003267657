import { Button, List, Paper } from "@mui/material";
import { ApiService } from "../services/ApiService";
import { useAuth } from "react-oidc-context";
import React, { useEffect } from "react";
import { Recipe } from "../model/Recipe";
import RecipeLine from "../pages/meal_planner/recipes/RecipeLine";
import { useNavigate } from "react-router-dom";

export default function RecipeBrowser(props: { applyRecipe?: (recipe: Recipe) => void }) {

    const auth = useAuth();
    const [recipes, setRecipes] = React.useState([] as Recipe[]);
    const navigate = useNavigate();

    useEffect(() => {
        ApiService.getRecipes(auth, 0)
            .then((response) => {
                setRecipes(response.data)
            })
    }, [])

    function handleOpenRecipeCreation(): any {
        navigate('/add_recipe');
    }

    return (
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            {recipes.map(recipe => {
                return (
                    <List>
                        <RecipeLine recipe={recipe} key={recipe.name} onApplyRecipe={props.applyRecipe} />
                    </List>
                )
            })}
            <Button variant="contained" sx={{ mt: 3, ml: 1 }} onClick={handleOpenRecipeCreation}>Add</Button>
        </Paper>
    );
}