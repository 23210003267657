import { Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { ApiService } from "../../services/ApiService";
import { Ingredient } from "../../model/Ingredient";
import { useEffect, useState } from "react";
import { useAuth } from "react-oidc-context";


export default function ListIngredients() {

    const auth = useAuth();
    const [ingredients, setIngredients] = useState<Ingredient[]>([]);

    useEffect(() => {
        ApiService.getIngredients().then(response => {
            setIngredients(response.data);
        })
    }, [])

    return (
        <Stack spacing={2}>
            Ingredients
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Ingredient</TableCell>
                            <TableCell align="right">Calories in 100g</TableCell>
                            <TableCell align="right">Fats in 100g&nbsp;(g)</TableCell>
                            <TableCell align="right">Carbs in 100g&nbsp;(g)</TableCell>
                            <TableCell align="right">Proteins in 100g&nbsp;(g)</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {ingredients.map((row) => (
                            <TableRow
                                key={row.name}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    <a href={"/update_ingredient/" + row.id}>{row.name}</a>
                                </TableCell>
                                <TableCell align="right">{row.kcalIn100g}</TableCell>
                                <TableCell align="right">{row.fatsIn100g}</TableCell>
                                <TableCell align="right">{row.carbohydratesIn100g}</TableCell>
                                <TableCell align="right">{row.proteinsIn100g}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Stack>)
}