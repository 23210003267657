import { Button, Paper, Stack, TextField } from "@mui/material";
import { ApiService } from "../../services/ApiService";
import { Ingredient } from "../../model/Ingredient";
import { useState } from "react";
import { useAuth } from "react-oidc-context";


export default function AddIngredient() {

    const auth = useAuth();
    const [name, setName] = useState<string>();
    const [weight, setWeight] = useState<number>();
    const [defaultWeight, setDefaultWeight] = useState<number>();
    const [glycemicIndex, setGlycemicIndex] = useState<number>();
    const [carbohydratesIn100g, setCarbohydratesIn100g] = useState<number>();
    const [proteinsIn100g, setProteinsIn100g] = useState<number>();
    const [fatsIn100g, setFatsIn100g] = useState<number>();
    const [kcalIn100g, setKcalIn100g] = useState<number>();


    function save() {
        ApiService.addIngredient({
            name: name,
            weight: 100,
            defaultWeight: 1,
            glycemicIndex: 1,
            carbohydratesIn100g: carbohydratesIn100g,
            proteinsIn100g: proteinsIn100g,
            fatsIn100g: fatsIn100g,
            kcalIn100g: kcalIn100g
        } as Ingredient, auth);
    }

    return (
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 } }}>
            <Stack spacing={2}>
                Add ingredients
                <TextField
                    required
                    id="name"
                    name="name"
                    label="Name"
                    fullWidth
                    variant="standard"
                    onChange={(event: any) => {
                        setName(event.target.value)
                    }}
                    value={name}
                />
                <TextField
                    required
                    id="kcal"
                    name="kcal"
                    label="kcal"
                    fullWidth
                    variant="standard"
                    onChange={(event: any) => {
                        setKcalIn100g(event.target.value)
                    }}
                    value={kcalIn100g}
                />
                <TextField
                    required
                    id="carbohydrates"
                    name="carbohydrates"
                    label="carbohydrates"
                    fullWidth
                    variant="standard"
                    onChange={(event: any) => {
                        setCarbohydratesIn100g(event.target.value)
                    }}
                    value={carbohydratesIn100g}
                />
                <TextField
                    required
                    id="proteins"
                    name="proteins"
                    label="proteins"
                    fullWidth
                    variant="standard"
                    onChange={(event: any) => {
                        setProteinsIn100g(event.target.value)
                    }}
                    value={proteinsIn100g}
                />
                <TextField
                    required
                    id="fats"
                    name="fats"
                    label="fats"
                    fullWidth
                    variant="standard"
                    onChange={(event: any) => {
                        // props.onChangeSelectedIngredientsWeight(props.index, event.target.value)
                    }}
                    value={fatsIn100g}
                />
                <Button variant="contained" sx={{ mt: 3, ml: 1 }} onClick={save}>Add</Button>
            </Stack>
        </Paper>
    )
}