import axios from "axios";
import { Day as MealsOfDay } from "../model/Day"
import { formatToDateOnly } from "../Common";
import { AuthContextProps } from "react-oidc-context";
import { Ingredient } from "../model/Ingredient";
import { Recipe } from "../model/Recipe";

export class ApiService {

    private static baseUrl = 'https://meal-calculator.siof.pl/api/';

    static async getIngredients() {
        return axios.get<Ingredient[]>(this.baseUrl + "ingredients");

    }

    static async getIngredient(ingredientId: number) {
        return axios.get<Ingredient>(this.baseUrl + "ingredients/" + ingredientId);

    }

    static async removeIngredient(ingredientId: number) {
        return axios.delete<any>(this.baseUrl + "ingredients/" + ingredientId);

    }

    static async updateIngredient(ingredient: Ingredient, auth: AuthContextProps) {
        let config = {
            headers: {
                Authorization: 'Bearer ' + auth.user?.access_token,
            }
        }

        axios.put<Ingredient>(this.baseUrl + `ingredients/${ingredient.id}`, ingredient, config);
    }

    static async addIngredient(ingredient: Ingredient, auth: AuthContextProps) {
        let config = {
            headers: {
                Authorization: 'Bearer ' + auth.user?.access_token,
            }
        }

        axios.post<Ingredient>(this.baseUrl + `ingredients`, [ingredient], config);
    }

    static async getMealsOfDay(dateFrom: Date, dateTo: Date, auth: AuthContextProps) {
        let config = {
            headers: {
                Authorization: 'Bearer ' + auth.user?.access_token,
            }
        }

        return axios.get<MealsOfDay[]>(this.baseUrl + `days/meals?fromDate=${formatToDateOnly(dateFrom)}&toDate=${formatToDateOnly(dateTo)}`, config);
    }

    static async getRecipes(auth: AuthContextProps, page: number) {
        let config = {
            headers: {
                Authorization: 'Bearer ' + auth.user?.access_token,
            }
        }

        return axios.get<any[]>(this.baseUrl + `recipes?page=${page}&results=100`, config);
    }

    static async addRecipe(recipe: Recipe, auth: AuthContextProps) {
        let config = {
            headers: {
                Authorization: 'Bearer ' + auth.user?.access_token,
            }
        }

        axios.post<Recipe>(this.baseUrl + `recipes`, recipe, config);
    }

    static async updateRecipe(recipe: Recipe, auth: AuthContextProps) {
        let config = {
            headers: {
                Authorization: 'Bearer ' + auth.user?.access_token,
            }
        }

        axios.put<Recipe>(this.baseUrl + `recipes/` + recipe.id, recipe, config);
    }
}